export const TestIDs = {
  actionsMenu: 'mobileactionsmenu',
  knob: 'mobileactionsmenu-knob',
  panel: 'mobileactionsmenu-panel',
  actions: {
    navigation: 'mobileactionsmenu-navigation',
    phone: 'mobileactionsmenu-phone',
    email: 'mobileactionsmenu-email',
    address: 'mobileactionsmenu-address',
    socialLinks: 'mobileactionsmenu-socialLinks',
  },
  buttonMenu: {
    root: 'mobileactionsmenu-buttonmenu',
    panel: 'mobileactionsmenu-buttonmenu-panel',
    headerTitle: 'mobileactionsmenu-buttonmenu-header-title',
    headerCloseBtn: 'mobileactionsmenu-buttonmenu-header-closebtn',
  },
};
